import { render, staticRenderFns } from "./ServiceView.vue?vue&type=template&id=47b9df59&scoped=true"
import script from "./ServiceView.vue?vue&type=script&lang=js"
export * from "./ServiceView.vue?vue&type=script&lang=js"
import style0 from "./ServiceView.vue?vue&type=style&index=0&id=47b9df59&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47b9df59",
  null
  
)

export default component.exports